import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Image, TextInput, TouchableOpacity, Modal, Alert, Platform } from 'react-native';
import { API_URL } from '../services/api';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function CardScreen({ route, navigation }) {
  const { cardId } = route.params;
  const [card, setCard] = useState(null);
  const [barcodeUrl, setBarcodeUrl] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [isBarcode, setIsBarcode] = useState(true);
  const [modalVisible, setModalVisible] = useState(false); // State for modal visibility
  const [cardNumber, setCardNumber] = useState('');
  const [storeName, setStoreName] = useState('');

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        const userId = await AsyncStorage.getItem('user_id');
        if (!userId) {
          console.error('No userId found in storage');
          return;
        }

        // Fetch card data based on userId and cardId
        const response = await axios.get(`${API_URL}/cards/${userId}/${cardId}`);
        const cardData = response.data;
        setCard(cardData);
        setCardNumber(cardData.barcode);
        setStoreName(cardData.cardname);

        // Adjust the barcode API URL to return as PNG format with cache busting
        const barcodeApiUrl = `https://bwipjs-api.metafloor.com/?bcid=code128&text=${cardData.barcode}&scale=4&includetext&paddingwidth=20&paddingheight=20&backgroundcolor=FFFFFF&barcolor=000000&imagetype=png&_=${new Date().getTime()}`;
        setBarcodeUrl(barcodeApiUrl);

        const qrCodeApiUrl = `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${cardData.barcode}`;
        setQrCodeUrl(qrCodeApiUrl);
      } catch (error) {
        console.error('Error fetching card data:', error);
      }
    };

    fetchCardData();
  }, [cardId]);

  // Function to handle card update
  const handleUpdateCard = async () => {
    try {
      const userId = await AsyncStorage.getItem('user_id'); // Retrieve user ID
  
      // Update the card details by sending cardNumber and storeName
      const response = await axios.put(`${API_URL}/cards/${userId}/${cardId}`, {
        cardNumber,         // Barcode value
        cardname: storeName // Store name value
      });
  
      Alert.alert('Success', 'Card updated successfully!');
  
      // Re-fetch the updated card data to refresh the barcode and QR code
      const updatedCardResponse = await axios.get(`${API_URL}/cards/${userId}/${cardId}`);
      const updatedCardData = updatedCardResponse.data;
      setCard(updatedCardData);
      setCardNumber(updatedCardData.barcode); // Update barcode
      setStoreName(updatedCardData.cardname); // Update store name
  
      // Re-generate the barcode and QR code URLs based on the updated data
      const barcodeApiUrl = `https://bwipjs-api.metafloor.com/?bcid=code128&text=${updatedCardData.barcode}&scale=4&includetext&paddingwidth=20&paddingheight=20&backgroundcolor=FFFFFF&barcolor=000000&imagetype=png&_=${new Date().getTime()}`;
      setBarcodeUrl(barcodeApiUrl);
  
      const qrCodeApiUrl = `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${updatedCardData.barcode}`;
      setQrCodeUrl(qrCodeApiUrl);
  
      setModalVisible(false); // Close the modal after successful update and refresh
    } catch (error) {
      console.error('Error updating card:', error.response?.data || error.message); // Log the actual error message
      Alert.alert('Error', 'Failed to update card.');
    }
  };
  
  const handleDeleteCard = () => {
    if (Platform.OS === 'web') {
      // Custom modal or window.confirm for web
      if (window.confirm('Are you sure you want to delete this card?')) {
        deleteCard(); // Proceed with deletion
      }
    } else {
      // Use Alert for mobile platforms
      Alert.alert(
        'Confirm Deletion',
        'Are you sure you want to delete this card?',
        [
          { text: 'Cancel', style: 'cancel' },
          {
            text: 'Delete',
            style: 'destructive',
            onPress: deleteCard, // Call the delete function
          },
        ],
        { cancelable: true }
      );
    }
  };
  
  // Function to actually delete the card
  const deleteCard = async () => {
    try {
      const userId = await AsyncStorage.getItem('user_id'); // Retrieve user ID
      const response = await axios.delete(`${API_URL}/cards/${userId}/${cardId}`);
      Alert.alert('Success', 'Card deleted successfully!');
      navigation.goBack(); // Navigate back after deletion
    } catch (error) {
      console.error('Error deleting card:', error.response?.data || error.message);
      Alert.alert('Error', 'Failed to delete card.');
    }
  };
  

  // Function to toggle between Barcode and QR Code
  const handleToggle = () => {
    setIsBarcode(!isBarcode); // Toggle between Barcode and QR Code
  };

  return (
    <View style={styles.container}>
      {card ? (
        <>
          <Text style={styles.title}>Store: {storeName}</Text>

          {/* Display the barcode or QR code based on the state */}
          <View style={styles.codeContainer}>
            {isBarcode ? (
              barcodeUrl ? (
                <Image
                  source={{ uri: barcodeUrl }}
                  style={{ width: 300, height: 150, resizeMode: 'contain' }} // Static width and height for barcode
                />
              ) : (
                <Text>Loading barcode...</Text>
              )
            ) : (
              qrCodeUrl && (
                <Image
                  source={{ uri: qrCodeUrl }}
                  style={styles.qrCode}
                />
              )
            )}
          </View>

          {/* Toggle between Barcode and QR Code */}
          <TouchableOpacity style={styles.switchButton} onPress={handleToggle}>
            <Text style={styles.switchButtonText}>
              {isBarcode ? 'Switch to QR Code' : 'Switch to Barcode'}
            </Text>
          </TouchableOpacity>

          {/* Settings button with same style as the switch button */}
          <TouchableOpacity style={styles.switchButton} onPress={() => setModalVisible(true)}>
            <Text style={styles.switchButtonText}>Settings</Text>
          </TouchableOpacity>

          {/* Modal for update and delete functionality */}
          <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => setModalVisible(false)}
          >
            <View style={styles.modalContainer}>
              <View style={styles.modalContent}>
                <TextInput
                  style={styles.input}
                  placeholder="Card Number"
                  placeholderTextColor="#A5A5A5"
                  value={cardNumber}
                  onChangeText={setCardNumber}
                  keyboardType="numeric"
                />
                <TextInput
                  style={styles.input}
                  placeholder="Store Name"
                  placeholderTextColor="#A5A5A5"
                  value={storeName}
                  onChangeText={setStoreName}
                />

                <TouchableOpacity style={styles.updateButton} onPress={handleUpdateCard}>
                  <Text style={styles.updateButtonText}>Update Card</Text>
                </TouchableOpacity>

                <TouchableOpacity style={styles.deleteButton} onPress={handleDeleteCard}>
                  <Text style={styles.deleteButtonText}>Delete Card</Text>
                </TouchableOpacity>

                <TouchableOpacity style={styles.closeButton} onPress={() => setModalVisible(false)}>
                  <Text style={styles.closeButtonText}>Close</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
        </>
      ) : (
        <Text>Loading card data...</Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2E1A47',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#fff',
    marginBottom: 40, // Increased margin for space between title and barcode
  },
  codeContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 40, // Increased margin to maintain layout consistency when switching
  },
  qrCode: {
    width: 200,
    height: 200,
    resizeMode: 'contain',
  },
  switchButton: {
    width: '90%',
    height: 50,
    backgroundColor: '#6C43E0',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginVertical: 20, // Added vertical margin for consistent spacing
  },
  switchButtonText: {
    color: '#fff',
    fontSize: 18,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: '80%',
    backgroundColor: '#3E2A67',
    borderRadius: 10,
    padding: 20,
    alignItems: 'center',
  },
  input: {
    width: '100%',
    height: 50,
    backgroundColor: '#fff',
    borderRadius: 10,
    paddingLeft: 15,
    marginBottom: 20,
  },
  updateButton: {
    width: '100%',
    height: 50,
    backgroundColor: '#6C43E0',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginBottom: 10,
  },
  updateButtonText: {
    color: '#fff',
    fontSize: 18,
  },
  deleteButton: {
    width: '100%',
    height: 50,
    backgroundColor: '#E43E3E',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginBottom: 10,
  },
  deleteButtonText: {
    color: '#fff',
    fontSize: 18,
  },
  closeButton: {
    width: '100%',
    height: 50,
    backgroundColor: '#888',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  closeButtonText: {
    color: '#fff',
    fontSize: 18,
  },
});
