import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { API_URL } from '../services/api';
import { useNavigation } from '@react-navigation/native';

export default function AddCardScreen() {
  const [cardNumber, setCardNumber] = useState('');
  const [storeName, setStoreName] = useState('');
  const navigation = useNavigation();
  const [userId, setUserId] = useState(null);

  // Fetch the userId from AsyncStorage when the screen is loaded
  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const storedUserId = await AsyncStorage.getItem('user_id'); // Retrieve user_id from storage
        if (storedUserId) {
          setUserId(storedUserId);
        } else {
          Alert.alert('Error', 'No userId found. Please login again.');
        }
      } catch (error) {
        console.error('Error retrieving userId from storage:', error);
      }
    };

    fetchUserId();
  }, []);

  const handleSaveCard = async () => {
    if (!cardNumber.trim() || !storeName.trim()) {
      Alert.alert('Error', 'Both card number and store name are required');
      return;
    }

    if (!userId) {
      Alert.alert('Error', 'No userId available. Please login again.');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/cards`, {
        cardNumber,
        cardname: storeName, // Use storeName as cardname in the request
        userid: userId,      // Send userId for database reference
      });

      if (response.data.success) {
        Alert.alert('Success', 'Card added successfully!');
        navigation.goBack();
      } else {
        Alert.alert('Error', 'There was a problem saving the card.');
      }
    } catch (error) {
      console.error('Error saving card:', error.response || error);
      Alert.alert('Error', 'There was a problem saving the card.');
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Add Loyalty Card</Text>

      <TextInput
        style={styles.input}
        placeholder="Card Number"
        placeholderTextColor="#A5A5A5"
        value={cardNumber}
        onChangeText={setCardNumber}
        keyboardType="numeric"
      />

      <TextInput
        style={styles.input}
        placeholder="Store Name"
        placeholderTextColor="#A5A5A5"
        value={storeName}
        onChangeText={setStoreName}
      />

      <TouchableOpacity style={styles.saveButton} onPress={handleSaveCard}>
        <Text style={styles.saveButtonText}>Save Card</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#2E1A47',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  title: {
    color: '#fff',
    fontSize: 24,
    marginBottom: 20,
  },
  input: {
    width: '100%',
    height: 50,
    backgroundColor: '#3E2A67',
    borderRadius: 10,
    color: '#fff',
    paddingLeft: 15,
    marginBottom: 20,
  },
  saveButton: {
    width: '100%',
    height: 50,
    backgroundColor: '#6C43E0',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  saveButtonText: {
    color: '#fff',
    fontSize: 18,
  },
});
