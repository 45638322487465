import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import axios from 'axios';
import { IMAGE_URL, API_URL } from '../services/api';
import { useFocusEffect } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function AccountDetailsScreen({ route, navigation }) {
  const [user, setUser] = useState(route.params.user);

  useFocusEffect(
    React.useCallback(() => {
      const fetchUpdatedUserDetails = async () => {
        try {
          const token = await AsyncStorage.getItem('token'); // Get token from local storage
          const response = await axios.get(`${API_URL}/user/${user.id}`, {
            headers: {
              'Authorization': `Bearer ${token}`, // Add token to the request headers
            },
          });
          setUser(response.data.user);
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      };

      fetchUpdatedUserDetails();
    }, [])
  );

  return (
    <View style={styles.container}>
      <Image
        source={{ uri: `${IMAGE_URL}/avatar/${user.avatar || 'default_avatar.png'}` }}
        style={styles.avatarTop}
      />
      <View style={styles.header}>
        <Text style={styles.username}>{user.username}</Text>
      </View>
      <View style={styles.detailsContainer}>
        <View style={styles.detailItem}>
          <Text style={styles.detailLabel}>Email:</Text>
          <Text style={styles.detailValue}>{user.email}</Text>
        </View>
        <View style={styles.detailItem}>
          <Text style={styles.detailLabel}>Joined:</Text>
          <Text style={styles.detailValue}>{new Date(user.created_at).toLocaleDateString()}</Text>
        </View>
      </View>
      <TouchableOpacity
        style={styles.modifyButton}
        onPress={() => navigation.navigate('UpdateContactDetails', { user })}
      >
        <Text style={styles.modifyButtonText}>Modify Contact Details</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#2E1A47', // Purple background color
  },
  avatarTop: {
    width: '100%',
    height: 200,
    marginBottom: 16,
    resizeMode: 'cover',
  },
  header: {
    alignItems: 'center',
    marginBottom: 32,
  },
  username: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#fff', // White text color for username
  },
  detailsContainer: {
    width: '90%',
    borderRadius: 8,
    padding: 16,
    backgroundColor: '#3B2C58', // Darker background color for details
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
    marginBottom: 32,
  },
  detailItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
  detailLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#A5A5A5', // Gray text color for labels
  },
  detailValue: {
    fontSize: 16,
    color: '#fff', // White text color for values
  },
  modifyButton: {
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    width: '90%',
    backgroundColor: '#6C43E0', // Button background color
  },
  modifyButtonText: {
    fontSize: 18,
    color: '#FFFFFF', // White text color for the button
    fontWeight: 'bold',
  },
});
