import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, FlatList, Image, TextInput, Keyboard } from 'react-native';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/Ionicons';
import axios from 'axios';
import { API_URL, IMAGE_URL } from '../services/api';
import AsyncStorage from '@react-native-async-storage/async-storage';

function HomeScreen() {
  const navigation = useNavigation();
  const [cards, setCards] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [filteredCards, setFilteredCards] = useState([]); // State for filtered cards
  const isFocused = useIsFocused();

  // Fetch cards for the user
  const fetchCards = async () => {
    try {
      const userId = await AsyncStorage.getItem('user_id');
      if (!userId) {
        console.error('No userId found in storage');
        return;
      }

      const response = await axios.get(`${API_URL}/cards/${userId}`);
      setCards(response.data);
      setFilteredCards(response.data); // Initially, show all cards
    } catch (error) {
      console.error('Error fetching cards:', error);
    }
  };

  // Fetch cards when screen is focused
  useEffect(() => {
    if (isFocused) {
      fetchCards();
    }
  }, [isFocused]);

  // Filter cards based on search query
  useEffect(() => {
    if (searchQuery) {
      const filtered = cards.filter((card) =>
        card.cardname.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredCards(filtered);
    } else {
      setFilteredCards(cards); // If no search query, show all cards
    }
  }, [searchQuery, cards]);

  // When a card is pressed, navigate to the CardScreen with the card's id
  const handleCardPress = (cardId) => {
    navigation.navigate('CardScreen', { cardId });
  };

  // Clear search query and dismiss the keyboard
  const handleCancelSearch = () => {
    setSearchQuery('');
    Keyboard.dismiss();
  };

  const renderCard = ({ item }) => (
    <TouchableOpacity onPress={() => handleCardPress(item.id)} style={styles.cardContainer}>
      <Image source={{ uri: `${IMAGE_URL}/photos/${item.photos}` }} style={styles.logo} />
      <View style={styles.cardInfo}>
        <Text style={styles.storeName}>{item.cardname}</Text>
        
      </View>
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>Loyalty Cards</Text>
        <TouchableOpacity
          style={styles.addButton}
          onPress={() => navigation.navigate('AddCardScreen')}
        >
          <Icon name="add-circle" size={30} color="#fff" />
        </TouchableOpacity>
      </View>

      <View style={styles.searchContainer}>
        <TextInput
          style={styles.searchInput}
          placeholder="Search cards..."
          placeholderTextColor="#A5A5A5"
          value={searchQuery}
          onChangeText={setSearchQuery}
        />
        {searchQuery.length > 0 && (
          <TouchableOpacity style={styles.cancelButton} onPress={handleCancelSearch}>
            <Text style={styles.cancelButtonText}>Cancel</Text>
          </TouchableOpacity>
        )}
      </View>

      <FlatList
        data={filteredCards}
        renderItem={renderCard}
        keyExtractor={(item) => item.id.toString()}
        contentContainerStyle={styles.listContainer}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#2E1A47',
    justifyContent: 'center',
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 15,
    backgroundColor: '#2E1A47',
  },
  title: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
  },
  addButton: {
    paddingRight: 10,
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#3B2C58',
    borderRadius: 10,
    marginHorizontal: 20,
    marginVertical: 10,
    paddingHorizontal: 15,
    height: 40,
  },
  searchInput: {
    flex: 1,
    color: '#fff',
    outline: 'none',  // This removes the default browser outline on web
  },
  cancelButton: {
    marginLeft: 10,
  },
  cancelButtonText: {
    color: '#A5A5A5',
    fontSize: 16,
  },
  listContainer: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  cardContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#3B2C58',
    padding: 15,
    borderRadius: 10,
    marginBottom: 15,
  },
  logo: {
    width: 50,
    height: 50,
    resizeMode: 'contain',
    marginRight: 15,
  },
  cardInfo: {
    flex: 1,
  },
  storeName: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#fff',
  },
  cashbackText: {
    fontSize: 16,
    color: '#A5A5A5',
  },
});


export default HomeScreen;
