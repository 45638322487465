import React, { useState } from 'react'; 
import { View, Text, TextInput, TouchableOpacity, Alert, ActivityIndicator, Keyboard, TouchableWithoutFeedback, StyleSheet, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from '@react-navigation/native';
import axios from 'axios';
import { API_URL } from '../services/api'; 

export default function RecoverPasswordScreen() {
  const { colors } = useTheme();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false); 
  const [errorMessage, setErrorMessage] = useState(''); 
  const navigation = useNavigation();

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRecoverPassword = async () => {
    if (!email) {
      setErrorMessage('Please provide your email.');
      return;
    }

    if (!isValidEmail(email)) {
      setErrorMessage('Invalid email format.');
      return;
    }

    setLoading(true); 
    setErrorMessage(''); 

    try {
      const response = await axios.post(`${API_URL}/recover-password`, { email });
      setLoading(false); 

      if (response.data.success) {
        Alert.alert(
          'Success',
          response.data.message,
          [
            {
              text: 'OK',
              onPress: () => {
                setEmail(''); 
                navigation.navigate('Login'); 
              }
            }
          ]
        );
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error(error);
      setLoading(false); 
      setErrorMessage('An error occurred. Please try again.');
    }
  };

  return (
    <TouchableWithoutFeedback onPress={Platform.OS !== 'web' ? Keyboard.dismiss : undefined}>
      <View style={[styles.container, { backgroundColor: '#2E1A47' }]}>
        <Text style={styles.title}>Recover Password</Text>

        {errorMessage ? <Text style={styles.errorText}>{errorMessage}</Text> : null}

        <TextInput
          style={styles.input}
          placeholder="Email"
          value={email}
          onChangeText={setEmail}
          placeholderTextColor="#A5A5A5"
          keyboardType="email-address"
          autoCapitalize="none"
          autoCompleteType="email" 
          textContentType="emailAddress"
        />

        {loading ? (
          <ActivityIndicator size="large" color="#6C43E0" />
        ) : (
          <TouchableOpacity style={styles.button} onPress={handleRecoverPassword}>
            <Text style={styles.buttonText}>Send Recovery Email</Text>
          </TouchableOpacity>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2E1A47',
  },
  title: {
    color: '#fff',
    fontSize: 24,
    marginBottom: 20,
  },
  input: {
    width: '80%',
    height: 50,
    backgroundColor: '#3E2A67',
    borderRadius: 10,
    color: '#fff',
    paddingLeft: 15,
    marginBottom: 20,
  },
  button: {
    width: '80%',
    height: 50,
    backgroundColor: '#6C43E0',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginTop: 10,
  },
  buttonText: {
    color: '#fff',
    fontSize: 18,
  },
  errorText: {
    color: 'red',
    marginBottom: 20,
  },
});
