import React, { useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, Alert, StyleSheet, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { API_URL } from '../services/api';
import { useNavigation } from '@react-navigation/native';

export default function ChangePasswordScreen() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const navigation = useNavigation();

  const handleChangePassword = async () => {
    if (!currentPassword || !newPassword) {
      Alert.alert('Error', 'Both fields are required.');
      return;
    }

    try {
      const userId = await AsyncStorage.getItem('user_id');
      const response = await axios.put(`${API_URL}/user/${userId}/change-password`, {
        currentPassword,
        newPassword,
      });

      if (response.data.success) {
        Alert.alert('Success', 'Password changed successfully!');
        navigation.goBack(); // Navigate back after password change
      } else {
        Alert.alert('Error', response.data.message || 'Password change failed.');
      }
    } catch (error) {
      console.error('Error changing password:', error.response?.data || error.message);
      Alert.alert('Error', 'There was a problem changing your password.');
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Change Password</Text>

      <TextInput
        style={styles.input}
        placeholder="Current Password"
        placeholderTextColor="#A5A5A5"
        secureTextEntry={true}
        value={currentPassword}
        onChangeText={setCurrentPassword}
        textContentType="password"
        autoCompleteType="password"
        autoCapitalize="none"
      />

      <TextInput
        style={styles.input}
        placeholder="New Password"
        placeholderTextColor="#A5A5A5"
        secureTextEntry={true}
        value={newPassword}
        onChangeText={setNewPassword}
        textContentType="newPassword"
        autoCompleteType="password"
        autoCapitalize="none"
      />

      <TouchableOpacity style={styles.changeButton} onPress={handleChangePassword}>
        <Text style={styles.changeButtonText}>Change Password</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#2E1A47', // Background color as requested
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  title: {
    color: '#fff',
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center',
  },
  input: {
    width: '100%',
    height: 50,
    backgroundColor: '#3B2C58', // Darker background for inputs
    borderRadius: 10,
    color: '#fff',
    paddingLeft: 15,
    marginBottom: 20,
    fontSize: 16,
  },
  changeButton: {
    width: '100%',
    height: 50,
    backgroundColor: '#6C43E0', // Button color
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  changeButtonText: {
    color: '#fff',
    fontSize: 18,
  },
});
