import React, { useState, useEffect, useCallback } from 'react'; 
import { View, Text, TouchableOpacity, StyleSheet, Image, Share, Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import axios from 'axios';
import { API_URL, IMAGE_URL } from '../services/api';

export default function SettingsScreen() {
  const [user, setUser] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const navigation = useNavigation();

  // Fetch user data
  const fetchUserData = async () => {
    const userId = await AsyncStorage.getItem('user_id');
    if (userId) {
      try {
        const response = await axios.get(`${API_URL}/user/${userId}`);
        setUser(response.data.user);
      } catch (error) {
        console.error('Error fetching user data:', error);
        Alert.alert('Error', 'Failed to fetch user data. Please log in again.');
        navigation.navigate('Login');
      }
    } else {
      Alert.alert('Error', 'User not logged in. Please log in again.');
      navigation.navigate('Login');
    }
  };

  // Handle side effects when the component is focused
  useFocusEffect(
    useCallback(() => {
      fetchUserData();
    }, [refreshKey])
  );

  const handleLogout = async () => {
    try {
      // Remove all user-related data to prevent auto-login
      await AsyncStorage.removeItem('user_id');
      await AsyncStorage.removeItem('token'); // Clear token
      await AsyncStorage.removeItem('username'); // Clear username for auto-login
      await AsyncStorage.removeItem('rememberMe'); // Clear rememberMe option
      
      if (Platform.OS !== 'web') {
        await SecureStore.deleteItemAsync('password'); // Clear password for mobile
      } else {
        await AsyncStorage.removeItem('password'); // Clear password for web
      }
  
      // Clear axios default authorization header
      axios.defaults.headers.common['Authorization'] = '';
  
      // Redirect to login screen after successful logout
      navigation.replace('Login');
    } catch (error) {
      console.error('Error during logout:', error);
      Alert.alert('Error', 'There was a problem logging out. Please try again.');
    }
  };
  

  const handleChangePassword = () => {
    navigation.navigate('ChangePassword');
  };

  const handleAccountDetails = () => {
    navigation.navigate('AccountDetails', { user });
  };

  const handleShareApp = async () => {
    try {
      const result = await Share.share({
        message: 'Check out this amazing app: https://loyaltycards.webcodeapp.ro',
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          console.log(`Shared with activity type: ${result.activityType}`);
        } else {
          console.log('Shared successfully');
        }
      } else if (result.action === Share.dismissedAction) {
        console.log('Share dismissed');
      }
    } catch (error) {
      console.error('Error sharing the app:', error);
    }
  };

  if (!user) {
    return (
      <View style={styles.container}>
        <Text style={styles.loadingText}>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Image 
          source={{ uri: `${IMAGE_URL}/avatar/${user.avatar}` }} 
          style={styles.avatar} 
        />
        <Text style={styles.username}>{user.username}</Text>
      </View>
      <TouchableOpacity style={[styles.optionButton, { backgroundColor: '#3B2C58' }]} onPress={handleAccountDetails}>
        <Text style={styles.optionText}>Account</Text>
      </TouchableOpacity>
      <TouchableOpacity style={[styles.optionButton, { backgroundColor: '#3B2C58' }]} onPress={handleChangePassword}>
        <Text style={styles.optionText}>Change Password</Text>
      </TouchableOpacity>
      <TouchableOpacity style={[styles.optionButton, { backgroundColor: '#3B2C58' }]} onPress={handleShareApp}>
        <Text style={styles.optionText}>Share App</Text>
      </TouchableOpacity>
      <TouchableOpacity style={[styles.optionButton, { backgroundColor: '#3B2C58' }]} onPress={handleLogout}>
        <Text style={styles.optionText}>Logout</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#2E1A47',
    justifyContent: 'center',
  },
  loadingText: {
    color: '#fff',
    textAlign: 'center',
  },
  header: {
    alignItems: 'center',
    padding: 16,
    marginBottom: 16,
  },
  avatar: {
    width: 80,
    height: 80,
    borderRadius: 40,
    marginBottom: 8,
  },
  username: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#fff',
  },
  optionButton: {
    width: '90%',
    paddingVertical: 15,
    justifyContent: 'center',
    alignItems: 'flex-start', // Align text to the left
    paddingLeft: 20, // Add left padding for better alignment
    borderRadius: 10, // Optional: Rounded corners
    marginVertical: 10, // Space between buttons
    alignSelf: 'center',
  },
  optionText: {
    color: '#fff',
    fontSize: 18,
  },
});
