import React from 'react';  
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Ionicons } from '@expo/vector-icons';
import LoginScreen from './src/screens/LoginScreen';
import SignupScreen from './src/screens/SignupScreen';
import HomeScreen from './src/screens/HomeScreen';
import OffersScreen from './src/screens/OffersScreen';
import SettingsScreen from './src/screens/SettingsScreen';
import AddCardScreen from './src/screens/AddCardScreen';
import CardScreen from './src/screens/CardScreen';
import ChangePasswordScreen from './src/screens/ChangePasswordScreen';
import AccountDetailsScreen from './src/screens/AccountDetailsScreen';
import UpdateContactDetailsScreen from './src/screens/UpdateContactDetailsScreen'; // Import UpdateContactDetailsScreen
import RecoverPasswordScreen from './src/screens/RecoverPasswordScreen'; // Import RecoverPasswordScreen

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function HomeTabs() {
  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarIcon: ({ focused, color, size }) => {
          let iconName;
          if (route.name === 'My Cards') {
            iconName = focused ? 'card' : 'card-outline';
          } else if (route.name === 'Offers') {
            iconName = focused ? 'pricetags' : 'pricetags-outline';
          } else if (route.name === 'Settings') {
            iconName = focused ? 'settings' : 'settings-outline';
          }

          return <Ionicons name={iconName} size={size} color={color} />;
        },
        tabBarActiveTintColor: '#ffffff',  
        tabBarInactiveTintColor: 'gray',  
        tabBarStyle: { backgroundColor: '#2E1A47' }, 
        headerTitleAlign: 'center',
        headerStyle: { backgroundColor: '#2E1A47' }, 
        headerTintColor: '#fff',
        headerTitleStyle: { fontSize: 24 },
      })}
    >
      <Tab.Screen name="My Cards" component={HomeScreen} />
      <Tab.Screen 
        name="Offers" 
        component={OffersScreen}
        listeners={{
          tabPress: e => {
            e.preventDefault(); // Disable the Offers tab
          },
        }}
      />
      <Tab.Screen name="Settings" component={SettingsScreen} />
    </Tab.Navigator>
  );
}

function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Login">
        <Stack.Screen 
          name="Login" 
          component={LoginScreen} 
          options={{ headerShown: false }} 
        />
        <Stack.Screen 
          name="Signup" 
          component={SignupScreen} 
          options={{ headerShown: false }} 
        />
        <Stack.Screen 
          name="HomeTabs" 
          component={HomeTabs} 
          options={{ headerShown: false }} 
        />
        <Stack.Screen 
          name="AddCardScreen" 
          component={AddCardScreen} 
          options={{
            headerStyle: { backgroundColor: '#2E1A47' },
            headerTintColor: '#fff', 
            headerTitle: 'Add Card',
          }}
        />
        <Stack.Screen 
          name="CardScreen"  
          component={CardScreen} 
          options={{
            headerStyle: { backgroundColor: '#2E1A47' },
            headerTintColor: '#fff', 
            headerTitle: 'Card Details',
          }}
        />
        <Stack.Screen 
          name="ChangePassword"  
          component={ChangePasswordScreen} 
          options={{
            headerStyle: { backgroundColor: '#2E1A47' },
            headerTintColor: '#fff', 
            headerTitle: 'Change Password',
          }}
        />
        <Stack.Screen 
          name="AccountDetails"  
          component={AccountDetailsScreen} 
          options={{
            headerStyle: { backgroundColor: '#2E1A47' },
            headerTintColor: '#fff', 
            headerTitle: 'Account Details',
          }}
        />
        <Stack.Screen 
          name="UpdateContactDetails"  
          component={UpdateContactDetailsScreen} 
          options={{
            headerStyle: { backgroundColor: '#2E1A47' },
            headerTintColor: '#fff', 
            headerTitle: 'Update Contact Details', 
          }}
        />
        <Stack.Screen 
          name="RecoverPassword"  
          component={RecoverPasswordScreen} // Add RecoverPasswordScreen
          options={{
            headerStyle: { backgroundColor: '#2E1A47' },
            headerTintColor: '#fff', 
            headerTitle: 'Recover Password', // Set title for Recover Password screen
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default App;
