import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, Alert, ActivityIndicator, Keyboard, TouchableWithoutFeedback, StyleSheet, Image, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import * as SecureStore from 'expo-secure-store'; // For mobile secure storage
import { API_URL } from '../services/api';

export default function LoginScreen() {
  const { colors } = useTheme();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigation = useNavigation();

  // Get device type
  const getDeviceType = () => {
    if (Platform.OS === 'ios') return 'iOS';
    if (Platform.OS === 'android') return 'Android';
    return 'Web';
  };

  // Get public IP address
  const getPublicIP = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('Error fetching public IP:', error);
      return null;
    }
  };

  // Load remembered data and Remember Me state
  useEffect(() => {
    const loadRememberMeState = async () => {
      try {
        const rememberMeOption = await AsyncStorage.getItem('rememberMe');
        if (rememberMeOption === 'true') {
          setRememberMe(true); // Set the checkbox state
          const rememberedUsername = await AsyncStorage.getItem('username');
          let rememberedPassword;

          if (Platform.OS !== 'web') {
            rememberedPassword = await SecureStore.getItemAsync('password'); // Secure storage for mobile
          } else {
            rememberedPassword = await AsyncStorage.getItem('password'); // AsyncStorage for web
          }

          if (rememberedUsername && rememberedPassword) {
            setUsername(rememberedUsername);
            setPassword(rememberedPassword);

            // Attempt auto-login if Remember Me is enabled
            await handleLogin(rememberedUsername, rememberedPassword); 
          }
        }
      } catch (error) {
        console.error('Error loading remembered data:', error);
      }
    };

    loadRememberMeState();
  }, []);

  // Handle Remember Me checkbox change
  const handleRememberMeChange = async () => {
    const newRememberMeState = !rememberMe;
    setRememberMe(newRememberMeState); // Toggle the checkbox state
    await AsyncStorage.setItem('rememberMe', newRememberMeState ? 'true' : 'false');
  };

  // Handle login submission (manual or automatic)
  const handleLogin = async (user = username, pass = password) => {
    if (!user.trim() || !pass.trim()) {
      Alert.alert('Error', 'Username and password are required');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await axios.post(`${API_URL}/login`, {
        username: user.trim(),
        password: pass.trim(),
        device: getDeviceType(),
        ip: await getPublicIP(),
      });

      if (response.data.success) {
        const { token, user_id } = response.data;
        await AsyncStorage.setItem('token', token);
        await AsyncStorage.setItem('user_id', user_id.toString());

        // Save login credentials based on Remember Me
        if (rememberMe) {
          await AsyncStorage.setItem('username', user);  // Store the username
          if (Platform.OS !== 'web') {
            await SecureStore.setItemAsync('password', pass); // SecureStore for mobile
          } else {
            await AsyncStorage.setItem('password', pass); // AsyncStorage for web
          }
        }

        navigation.replace('HomeTabs');
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || 'An error occurred during login. Please try again.');
      } else if (error.request) {
        setError('No response from the server. Please check your network.');
      } else {
        setError('An error occurred. Please try again.');
      }
      console.error('Login error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TouchableWithoutFeedback onPress={Platform.OS !== 'web' ? Keyboard.dismiss : undefined}>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#2E1A47' }}>
        <Text style={{ color: '#fff', fontSize: 24, marginBottom: 20 }}>Welcome Back</Text>

        <TextInput
          style={styles.input}
          placeholder="Email"
          placeholderTextColor="#A5A5A5"
          value={username}
          onChangeText={setUsername}
          autoCapitalize="none"
        />

        <TextInput
          style={styles.input}
          placeholder="Password"
          placeholderTextColor="#A5A5A5"
          secureTextEntry={!showPassword}
          value={password}
          onChangeText={setPassword}
        />

        <View style={styles.optionsContainer}>
          <TouchableOpacity onPress={handleRememberMeChange}>
            <Text style={styles.rememberMeText}>{rememberMe ? '☑' : '☐'} Remember Me</Text>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => setShowPassword(!showPassword)}>
            <Text style={styles.showPasswordText}>{showPassword ? 'Hide Password' : 'Show Password'}</Text>
          </TouchableOpacity>
        </View>

        {error ? <Text style={{ color: 'red', marginBottom: 20 }}>{error}</Text> : null}

        <TouchableOpacity style={styles.loginButton} onPress={() => handleLogin()}>
          {loading ? <ActivityIndicator color="#fff" /> : <Text style={styles.loginButtonText}>Log in</Text>}
        </TouchableOpacity>

        <View style={styles.separator}>
          <View style={styles.line} />
          <Text style={styles.orText}>or</Text>
          <View style={styles.line} />
        </View>

        <TouchableOpacity style={styles.googleButton} disabled>
          <View style={styles.googleIcon}>
            <Image source={require('../../assets/Gmail.png')} style={{ width: 30, height: 30 }} />
          </View>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => navigation.navigate('RecoverPassword')}>
          <Text style={styles.forgotPasswordText}>Forgot Password?</Text>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => navigation.navigate('Signup')}>
          <Text style={{ color: '#A5A5A5', marginTop: 20 }}>
            Don't have an account? <Text style={styles.loginLink}>Sign up</Text>
          </Text>
        </TouchableOpacity>
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  input: {
    width: '80%',
    height: 50,
    backgroundColor: '#3E2A67',
    borderRadius: 10,
    color: '#fff',
    paddingLeft: 15,
    marginBottom: 20,
  },
  optionsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80%',
    marginBottom: 20,
  },
  rememberMeText: {
    color: '#A5A5A5',
  },
  showPasswordText: {
    color: '#A5A5A5',
  },
  loginButton: {
    width: '80%',
    height: 50,
    backgroundColor: '#6C43E0',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 20,
  },
  loginButtonText: {
    color: '#fff',
    fontSize: 18,
  },
  separator: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    width: '80%',
  },
  line: {
    flex: 1,
    height: 1,
    backgroundColor: '#ccc',
  },
  orText: {
    marginHorizontal: 10,
    color: '#A5A5A5',
  },
  googleButton: {
    width: 50,
    height: 50,
    borderRadius: 25,
    borderColor: '#ccc',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  googleIcon: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  forgotPasswordText: {
    color: '#A5A5A5',
    marginTop: 20,
  },
  loginLink: {
    color: '#fff',
  },
});
